import { AutoGroupColumn, CurrencyColumn, DateColumn, IDataGridCellStyleParams, PercentageColumn } from '@/lib/common/components/grid/Columns'
import DataGrid, { DataGridInitEvent } from '@/lib/common/components/grid/Grid'
import { PerformanceRawData, SLEEVE_DISPLAY_TYPE, getPerformanceValue, PERFORMANCE_MEDIAN_KEYS, roundMarketValueWidgetValue, getGridExcelStyles, IPortfolioSettings } from '@/shared/api/services/portfolioService'
import useIsMobile from '@/shared/hooks/useIsMobile'
import { GridApi } from 'ag-grid-community'
import { useEffect, useState } from 'react'
import { EXPORT_CELL_CLASS, PerformanceGridAutoGroupCellRenderer } from './cellFramework/PerformanceGridAutoGroupCellRenderer'

import './PerformanceGrid.scss'

export interface PerformanceGridInitializeEvent {
  gridApi: GridApi,
  refreshGridExpand: (shouldExpandSleeves: boolean) => void,
}

export interface PerformanceGridProps {
  isFullScreenMode?: boolean,
  isSleevesExpanded: boolean,
  portfolioSettings: IPortfolioSettings,
  rows: PerformanceRawData[],
  isFullScreen: boolean,
  onGridInitialize: (event: PerformanceGridInitializeEvent) => void,
}

const PerformanceGrid = ({
  isFullScreenMode,
  isSleevesExpanded,
  portfolioSettings,
  rows,
  isFullScreen,
  onGridInitialize,
}: PerformanceGridProps) => {
  const performanceTabSettings = portfolioSettings?.performanceTab
  const [gridApi, setGridApi] = useState(null as GridApi)
  const isMobile = useIsMobile('xs')
  const maxLevel = (rows || []).reduce((max, item) => (item.Lvl > max ? item.Lvl : max), 0)

  useEffect(() => {
    refreshGridExpand(isSleevesExpanded)
  }, [isSleevesExpanded, gridApi, rows])

  const refreshGridExpand = (shouldExpandSleeves: boolean) => {
    if (gridApi) {
      // Collapse all nodes first
      gridApi.collapseAll()

      // Expand only the parent node (level 0)
      gridApi.forEachNode(node => {
        const data = node.data as PerformanceRawData

        if (data.DisplayPLIType === SLEEVE_DISPLAY_TYPE) {
          // Expand only the parent node
          node.setExpanded(shouldExpandSleeves)

          if (node.level === 0) {
            node.setExpanded(true)
          }
        }
      })
    }
  }

  const getPerformanceCellStyle = (params: IDataGridCellStyleParams) => {
    const data = (params.data || {}) as PerformanceRawData
    const styles = {} as any
    let borderColor = data && data.Color

    // get color from parent sleeves
    if (!borderColor) {
      let parentId = data && data.ParentPLISk
      const maxIndex = maxLevel < 10 ? maxLevel : 10

      // using for loop with break condition and limited index to avoid any logic error that leads to infinite loop
      for (let i = 0; i < maxIndex; i++) {
        const parent = (rows || []).find(r => (r.PLISk === parentId))

        if (parent) {
          borderColor = parent.Color

          if (borderColor) {
            // stop iteration if parent color found
            break
          }

          parentId = parent.ParentPLISk
        } else {
          // stop iteration on no parent
          break
        }
      }
    }

    styles['border-left'] = `5px solid ${borderColor}`
    styles['box-sizing'] = 'border-box !important'

    if (data.DisplayPLIType === SLEEVE_DISPLAY_TYPE) {
      styles['background-color'] = `${data && data.Color}1F`
    }

    return styles
  }

  const getRowStyle = (params: IDataGridCellStyleParams) => {
    const data = params?.data || {}
    const styles = {} as any

    if (data.DisplayPLIType === SLEEVE_DISPLAY_TYPE) {
      styles['background-color'] = `${data.Color}1F`
    }

    if (data?.name === 'Total Assets') {
      styles['font-weight'] = '600'
    }

    return styles
  }

  const onGridInit = (event: DataGridInitEvent) => {
    const { gridApi } = event
    setGridApi(gridApi)
    onGridInitialize({
      gridApi,
      refreshGridExpand,
    })
  }

  const getCurrencySymbol = (data) => {
    return data?.CurrencySymbol
  }

  const onRowStyle = (e) => {
    if (e.data.DisplayPLIType.includes('Benchmark')) {
      return {
        borderBottom: 0
      }
    }
  }

  return (
    <DataGrid className={`${isFullScreen ? 'full-screen-grid' : isFullScreenMode ? 'full-screen-grid-mobile' : ''} performance-grid`}
      showToolBar={false}
      rows={rows}
      enablePagination={false}
      enableTreeData={true}
      treeDataColumnId='PLISk'
      treeDataParentColumnId='ParentPLISk'
      defaultOpenGroupDataLevel={1}
      onInit={onGridInit}
      domLayout='normal'
      getRowStyle={onRowStyle}
      excelStyles={getGridExcelStyles({
        currencySymbol: portfolioSettings?.currency?.symbol,
        dateFormat: portfolioSettings?.dateFormat,
      })}
    >
      <AutoGroupColumn pinned='left' cellClass={EXPORT_CELL_CLASS} lockPinned={isMobile} disableFilterColumn={true} cellClassRules={{
        darkFont: params => params.data.DisplayPLIType === 'Sleeve',
      }} cellStyle={getPerformanceCellStyle} title='' field='Name' width={isMobile ? 200 : 400} autoGroupCellRenderer={PerformanceGridAutoGroupCellRenderer} />
      <DateColumn hide={!performanceTabSettings?.performanceInceptionDate?.display} dateFormat={portfolioSettings?.dateFormat} width={155} cellStyle={getRowStyle} disableFilterColumn={true} cellClassRules={{
        darkFont: params => params.data.DisplayPLIType === 'Sleeve',
      }} title='INCEPTION DATE' field='InceptDate' />
      <CurrencyColumn hide={!performanceTabSettings?.performanceMarketValue?.display} width={155} cellStyle={getRowStyle} headerClass='text-right five-text-break-right' disableFilterColumn={true} cellClassRules={{
        darkFont: params => params.data.DisplayPLIType === 'Sleeve',
      }} title='CURRENT MARKET VALUE' decimalScale={0} getCurrencySymbol={getCurrencySymbol} field='MV' valueGetter={(value) => {
        return roundMarketValueWidgetValue(value?.MV)
      }} />
      <PercentageColumn hide={!performanceTabSettings?.performanceMtd?.display} headerClass='text-right text-break-right' width={155} cellStyle={getRowStyle} disableFilterColumn={true} customFilter={PERFORMANCE_MEDIAN_KEYS} title='MONTH TO DATE (%)'
        cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} field='TwrMtd' valueGetter={(value) => {
          return getPerformanceValue(value, 'TwrMtd')
        }} />
      <PercentageColumn disableFilterColumn={true} cellStyle={getRowStyle} customFilter={PERFORMANCE_MEDIAN_KEYS} title='QUARTER TO DATE' width={155}
        field='TwrQtd' valueGetter={(value) => {
          return getPerformanceValue(value, 'TwrQtd')
        }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
      <PercentageColumn hide={!performanceTabSettings?.performanceCalendarYtd?.display} headerClass='text-right four-text-break-right' width={155} cellStyle={getRowStyle} disableFilterColumn={true} customFilter={PERFORMANCE_MEDIAN_KEYS} title='CALENDAR YEAR TO DATE (%)' cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }}
        field='TwrCytd' valueGetter={(value) => {
          return getPerformanceValue(value, 'TwrCytd')
        }} />
      <PercentageColumn hide={!performanceTabSettings?.performanceCumulativeSinceInception?.display} headerClass='text-right six-text-break-right' width={155} cellStyle={getRowStyle} disableFilterColumn={true} customFilter={PERFORMANCE_MEDIAN_KEYS} title='CUMULATIVE TRAILING 1 YEAR (%)' cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }}
        field='TwrTr1Y' valueGetter={(value) => {
          return getPerformanceValue(value, 'TwrTr1Y')
        }} />
      <PercentageColumn disableFilterColumn={true} cellStyle={getRowStyle} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceTabSettings?.performance2Yr?.display} title='ANNUALIZED 2 YEARS'
        width={160} field='TwrTr2Y' valueGetter={(value) => {
          return getPerformanceValue(value, 'TwrTr2Y')
        }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
      <PercentageColumn hide={!performanceTabSettings?.performanceAnnualizedSinceInception?.display} headerClass='text-right six-text-break-right' width={155} cellStyle={getRowStyle} disableFilterColumn={true} customFilter={PERFORMANCE_MEDIAN_KEYS} title='ANNUALIZED TRAILING 3 YEARS (%)' cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }}
        field='TwrTr3Y' valueGetter={(value) => {
          return getPerformanceValue(value, 'TwrTr3Y')
        }} />
      <PercentageColumn disableFilterColumn={true} cellStyle={getRowStyle} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceTabSettings?.performance4Yr?.display} title='ANNUALIZED 4 YEARS' width={160}
        field='TwrTr4Y' valueGetter={(value) => {
          return getPerformanceValue(value, 'TwrTr4Y')
        }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
      <PercentageColumn disableFilterColumn={true} cellStyle={getRowStyle} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceTabSettings?.performance5Yr?.display} title='ANNUALIZED 5 YEARS' width={160}
        field='TwrTr5Y' valueGetter={(value) => {
          return getPerformanceValue(value, 'TwrTr5Y')
        }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
      <PercentageColumn disableFilterColumn={true} cellStyle={getRowStyle} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceTabSettings?.performance6Yr?.display} title='ANNUALIZED 6 YEARS' width={160}
        field='TwrTr6Y' valueGetter={(value) => {
          return getPerformanceValue(value, 'TwrTr6Y')
        }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
      <PercentageColumn disableFilterColumn={true} cellStyle={getRowStyle} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceTabSettings?.performance7Yr?.display} title='ANNUALIZED 7 YEARS' width={160}
        field='TwrTr7Y' valueGetter={(value) => {
          return getPerformanceValue(value, 'TwrTr7Y')
        }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
      <PercentageColumn disableFilterColumn={true} cellStyle={getRowStyle} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceTabSettings?.performance8Yr?.display} title='ANNUALIZED 8 YEARS' width={160}
        field='TwrTr8Y' valueGetter={(value) => {
          return getPerformanceValue(value, 'TwrTr8Y')
        }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
      <PercentageColumn disableFilterColumn={true} cellStyle={getRowStyle} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceTabSettings?.performance9Yr?.display} title='ANNUALIZED 9 YEARS' width={160}
        field='TwrTr9Y' valueGetter={(value) => {
          return getPerformanceValue(value, 'TwrTr9Y')
        }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
      <PercentageColumn disableFilterColumn={true} cellStyle={getRowStyle} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceTabSettings?.performance10Yr?.display} title='ANNUALIZED 10 YEARS' width={160}
        field='TwrTr10Y' valueGetter={(value) => {
          return getPerformanceValue(value, 'TwrTr10Y')
        }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
      <PercentageColumn disableFilterColumn={true} cellStyle={getRowStyle} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceTabSettings?.performance11Yr?.display} title='ANNUALIZED 11 YEARS' width={160}
        field='TwrTr11Y' valueGetter={(value) => {
          return getPerformanceValue(value, 'TwrTr11Y')
        }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
      <PercentageColumn disableFilterColumn={true} cellStyle={getRowStyle} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceTabSettings?.performance12Yr?.display} title='ANNUALIZED 12 YEARS' width={160}
        field='TwrTr12Y' valueGetter={(value) => {
          return getPerformanceValue(value, 'TwrTr12Y')
        }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
      <PercentageColumn disableFilterColumn={true} cellStyle={getRowStyle} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceTabSettings?.performance13Yr?.display} title='ANNUALIZED 13 YEARS' width={160}
        field='TwrTr13Y' valueGetter={(value) => {
          return getPerformanceValue(value, 'TwrTr13Y')
        }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
      <PercentageColumn disableFilterColumn={true} cellStyle={getRowStyle} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceTabSettings?.performance14Yr?.display} title='ANNUALIZED 14 YEARS' width={160}
        field='TwrTr14Y' valueGetter={(value) => {
          return getPerformanceValue(value, 'TwrTr14Y')
        }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
      <PercentageColumn disableFilterColumn={true} cellStyle={getRowStyle} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceTabSettings?.performance15Yr?.display} title='ANNUALIZED 15 YEARS' width={160}
        field='TwrTr15Y' valueGetter={(value) => {
          return getPerformanceValue(value, 'TwrTr15Y')
        }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
      <PercentageColumn hide={!performanceTabSettings?.performanceAnnualizedSinceInception?.display} headerClass='text-right six-text-break-right' width={155} cellStyle={getRowStyle} disableFilterColumn={true} customFilter={PERFORMANCE_MEDIAN_KEYS} title='ANNUALIZED SINCE INCEPTION (%)' cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }}
        field='TwrItd' valueGetter={(value) => {
          return getPerformanceValue(value, 'TwrItd')
        }} />
      <PercentageColumn disableFilterColumn={true} cellStyle={getRowStyle} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceTabSettings?.performanceCumulativeSinceInception?.display} title='CUMULATIVE SINCE INCEPTION' width={200}
        field='TwrCumItd' valueGetter={(value) => {
          return getPerformanceValue(value, 'TwrCumItd')
        }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
      <PercentageColumn disableFilterColumn={true} cellStyle={getRowStyle} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceTabSettings?.performanceSinceFirstMonth?.display} title='ANNUALIZED SINCE FIRST FULL MONTH' width={211}
        field='TwrS1FM' valueGetter={(value) => {
          return getPerformanceValue(value, 'TwrS1FM')
        }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
      <PercentageColumn disableFilterColumn={true} cellStyle={getRowStyle} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceTabSettings?.performanceSinceFirstQuarter?.display} title='ANNUALIZED SINCE FIRST FULL QUARTER' width={211}
        field='TwrS1FQ' valueGetter={(value) => {
          return getPerformanceValue(value, 'TwrS1FQ')
        }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
      <PercentageColumn disableFilterColumn={true} cellStyle={getRowStyle} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceTabSettings?.performanceFiscalYtd?.display} title='FISCAL YEAR TO DATE - 1/31' width={205}
        field='TwrFytdJan' valueGetter={(value) => {
          return getPerformanceValue(value, 'TwrFytdJan')
        }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
      <PercentageColumn disableFilterColumn={true} cellStyle={getRowStyle} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceTabSettings?.performanceFiscalYtd?.display} title='FISCAL YEAR TO DATE - 2/28' width={205}
        field='TwrFytdFeb' valueGetter={(value) => {
          return getPerformanceValue(value, 'TwrFytdFeb')
        }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
      <PercentageColumn disableFilterColumn={true} cellStyle={getRowStyle} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceTabSettings?.performanceFiscalYtd?.display} title='FISCAL YEAR TO DATE - 3/31' width={205}
        field='TwrFytdMar' valueGetter={(value) => {
          return getPerformanceValue(value, 'TwrFytdMar')
        }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
      <PercentageColumn disableFilterColumn={true} cellStyle={getRowStyle} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceTabSettings?.performanceFiscalYtd?.display} title='FISCAL YEAR TO DATE - 4/30' width={205}
        field='TwrFytdApr' valueGetter={(value) => {
          return getPerformanceValue(value, 'TwrFytdApr')
        }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
      <PercentageColumn disableFilterColumn={true} cellStyle={getRowStyle} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceTabSettings?.performanceFiscalYtd?.display} title='FISCAL YEAR TO DATE - 5/31' width={205}
        field='TwrFytdMay' valueGetter={(value) => {
          return getPerformanceValue(value, 'TwrFytdMay')
        }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
      <PercentageColumn disableFilterColumn={true} cellStyle={getRowStyle} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceTabSettings?.performanceFiscalYtd?.display} title='FISCAL YEAR TO DATE - 6/30' width={205}
        field='TwrFytdJun' valueGetter={(value) => {
          return getPerformanceValue(value, 'TwrFytdJun')
        }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
      <PercentageColumn disableFilterColumn={true} cellStyle={getRowStyle} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceTabSettings?.performanceFiscalYtd?.display} title='FISCAL YEAR TO DATE - 7/31' width={205}
        field='TwrFytdJul' valueGetter={(value) => {
          return getPerformanceValue(value, 'TwrFytdJul')
        }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
      <PercentageColumn disableFilterColumn={true} cellStyle={getRowStyle} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceTabSettings?.performanceFiscalYtd?.display} title='FISCAL YEAR TO DATE - 8/30' width={205}
        field='TwrFytdAug' valueGetter={(value) => {
          return getPerformanceValue(value, 'TwrFytdAug')
        }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
      <PercentageColumn disableFilterColumn={true} cellStyle={getRowStyle} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceTabSettings?.performanceFiscalYtd?.display} title='FISCAL YEAR TO DATE - 9/30' width={205}
        field='TwrFytdSep' valueGetter={(value) => {
          return getPerformanceValue(value, 'TwrFytdSep')
        }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
      <PercentageColumn disableFilterColumn={true} cellStyle={getRowStyle} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceTabSettings?.performanceFiscalYtd?.display} title='FISCAL YEAR TO DATE - 10/31' width={205}
        field='TwrFytdOct' valueGetter={(value) => {
          return getPerformanceValue(value, 'TwrFytdOct')
        }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
      <PercentageColumn disableFilterColumn={true} cellStyle={getRowStyle} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceTabSettings?.performanceFiscalYtd?.display} title='FISCAL YEAR TO DATE - 11/30' width={205}
        field='TwrFytdNov' valueGetter={(value) => {
          return getPerformanceValue(value, 'TwrFytdNov')
        }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
      <PercentageColumn disableFilterColumn={true} cellStyle={getRowStyle} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceTabSettings?.performanceFiscalYtd?.display} title='FISCAL YEAR TO DATE - 12/31' width={205}
        field='TwrFytdDec' valueGetter={(value) => {
          return getPerformanceValue(value, 'TwrFytdDec')
        }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
    </DataGrid>
  )
}

export default PerformanceGrid
