import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { mainService } from '.'
import { IEmployeeAdminClientNavbarDisplayResult, ILegacyAppDisplayRuleResult, INavbarNavigationDisplayResult } from '@/shared/types/swagger'
import { ClientSettingsModel, DashboardSettings, DashboardView, getDefaultClientSettings } from '@/features/client/components/ClientSettings/ClientSettings.model'
import { selectAccountId, selectIsClientUser, selectIsImpersonating } from '@/features/auth/redux/authSelectors'

const legacyServiceWithTags = mainService.enhanceEndpoints({
  addTagTypes: [
    'DisplayRules',
    'NavbarRules',
    'EmployeeAdminClientNavbarRules',
    'ClientSettings',
    'DashboardView',
    'DashboardSettings',
  ],
})

const legacyService = legacyServiceWithTags.injectEndpoints({
  endpoints(build) {
    return {
      getDisplayRules: build.query<ILegacyAppDisplayRuleResult, void>({
        query: () => ({ url: '/LegacyApplication/DisplayRules', method: 'GET' }),
        providesTags: ['DisplayRules'],
      }),
      getNavbarRules: build.query<INavbarNavigationDisplayResult, string>({
        query: (CAID) => ({ url: `/LegacyApplication/NavbarRules?CAID=${CAID}`, method: 'GET' }),
        providesTags: ['NavbarRules'],
      }),
      getEmployeeClientNavbarRules: build.query<IEmployeeAdminClientNavbarDisplayResult, string>({
        query: (accountId) => ({ url: `/LegacyApplication/EmployeeAdminClientNavbarRules?accountId=${accountId}`, method: 'GET' }),
        providesTags: ['EmployeeAdminClientNavbarRules'],
      }),
      getClientSettingsByAccountId: build.query<ClientSettingsModel, string>({
        query: (accountId) => ({ url: `/LegacyApplication/AccountSettings/GetByAccountId/${accountId}`, method: 'GET' }),
        providesTags: ['ClientSettings'],
      }),
      getDashboardView: build.query<DashboardView, void>({
        query: () => ({ url: `/LegacyApplication/Dashboard/View`, method: 'GET' }),
        providesTags: ['DashboardView'],
      }),
      getDashboardSettings: build.query<DashboardSettings, void>({
        query: () => ({ url: `/LegacyApplication/Dashboard/Settings`, method: 'GET' }),
        providesTags: ['DashboardSettings'],
      }),
      getDashboardSettingsByAccountId: build.query<DashboardSettings, string>({
        query: (accountId) => ({ url: `/LegacyApplication/Dashboard/Settings/${accountId}`, method: 'GET' }),
        providesTags: (result, error, id) => {
          return [{
            type: 'DashboardSettings',
            id,
          }]
        },
      }),
      saveClientSettings: build.mutation<ClientSettingsModel, ClientSettingsModel>({
        query: (data) => ({
          url: '/LegacyApplication/SaveAccountSettings/Save',
          method: 'POST',
          data,
        }),
        invalidatesTags: ['NavbarRules', 'EmployeeAdminClientNavbarRules', 'ClientSettings', 'DashboardView', 'DashboardSettings'],
      }),
    }
  },
})

export const {
  useGetDisplayRulesQuery,
  useGetNavbarRulesQuery,
  useGetEmployeeClientNavbarRulesQuery,
  useGetClientSettingsByAccountIdQuery,
  useGetDashboardViewQuery,
  useGetDashboardSettingsQuery,
  useGetDashboardSettingsByAccountIdQuery,
  useSaveClientSettingsMutation,
} = legacyService

export function useGetDashboardSettings() {
  const isClientUser = useSelector(selectIsClientUser)
  const isImpersonating = useSelector(selectIsImpersonating)
  const accountId = useSelector(selectAccountId)
  const dashboardSettings = useGetDashboardSettingsQuery()
  const defaultSettings = getDefaultClientSettings(accountId, isClientUser || isImpersonating)

  const combinedData = useMemo(() => {
    if (dashboardSettings?.isError) {
      return defaultSettings
    }

    return dashboardSettings?.data || defaultSettings
  }, [dashboardSettings])

  return {
    ...dashboardSettings,
    data: combinedData,
  }
}
