import { GridApi } from 'ag-grid-community'
import { useEffect, useState } from 'react'
import { PerformanceRawData, getPerformanceValue, PERFORMANCE_MEDIAN_KEYS, IPortfolioSettings, getGridExcelStyles } from '@/shared/api/services/portfolioService'
import useIsMobile from '@/shared/hooks/useIsMobile'
import { IDataGridCellStyleParams, PercentageColumn } from '@/lib/common/components/grid/Columns'
import DataGrid, { DataGridInitEvent } from '@/lib/common/components/grid/Grid'
import { PerformanceSleeveColumn } from '@/screens/ClientPortfolio/Components/Grid/Column/PerformanceSleeveColumn'
import PerformanceGridFootNote from '@/screens/ClientPortfolio/Components/Grid/FootNote/PerformanceGridFootNote'
import { formatDate } from '@/lib/common/services/date/DateService'

import './PerformanceSummaryGrid.scss'

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height
  }
}

export interface PerformanceSummaryGridProps {
  portfolioSettings: IPortfolioSettings,
  rows: PerformanceRawData[],
  sendGridApi: (gridApi: GridApi) => void,
}

const PerformanceSummaryGrid = ({
  portfolioSettings,
  rows,
  sendGridApi
}: PerformanceSummaryGridProps) => {
  const performanceSummarySettings = portfolioSettings?.performanceSummary
  const isMobile = useIsMobile('xs')
  const isLargeMobile = useIsMobile('mm')
  const isMediumMobile = useIsMobile('md')
  const isVerticalTablet = useIsMobile('sm')
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
  const [columnSize, setcolumnSize] = useState()
  const sizesMap = {
    375: 170,
    430: 225,
    480: 270,
    768: 175,
    1024: 415,
    1440: 180
  }
  const isFootNoteHidden = !(rows || []).some(r => !!r.StatusAggregate)

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions())
      setcolumnSize(sizesMap[windowDimensions.width])
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  
  const getRowStyle = (params: IDataGridCellStyleParams) => {
    const data = params?.data || {}
    const styles = {} as any
    if (params.rowIndex <= 0) {
      styles['background-color'] = `${data.Color}1F`
    }
    if (data.DisplayPLIType === 'ValueAdded' || data.DisplayPLIType === 'Benchmark') {
      styles['font-style'] = 'italic'
    }
    if (data?.Name === 'Total Assets') {
      styles['font-weight'] = '600'
    }
    return styles
  }

  const onGridInit = (event: DataGridInitEvent) => {
    const { gridApi } = event
    sendGridApi(gridApi)
  }

  return (
    <div className='PerformanceSummaryGrid'>
      <DataGrid
        showToolBar={false}
        rows={rows}
        enablePagination={false}
        domLayout='autoHeight'
        className='PerformanceGrid'
        onInit={onGridInit}
        excelStyles={getGridExcelStyles({
          currencySymbol: portfolioSettings?.currency?.symbol,
          dateFormat: portfolioSettings?.dateFormat,
        })}
      >
        <PerformanceSleeveColumn pinned='left' lockPinned={true} disableFilterColumn={true} title=' ' width={250} resizable={false} headerName='nameColumn' field='Name' checkboxSelection={false} cellStyle={getRowStyle} />
        <PercentageColumn hide={!performanceSummarySettings?.performanceSumaryMtd?.display} customFilter={PERFORMANCE_MEDIAN_KEYS} headerClass='three-text-break-right mtd' title={`${isMobile ? 'MTD' : 'Month to Date'}`} disableFilterColumn={true} field='TwrMtd' cellStyle={getRowStyle} width={isMobile ? 50 : 110} valueGetter={value => {
          return getPerformanceValue(value, 'TwrMtd')
        }} />
        <PercentageColumn hide={!performanceSummarySettings?.performanceSumaryQtd?.display} customFilter={PERFORMANCE_MEDIAN_KEYS} headerClass='three-text-break-right mtd' title={`${isMobile ? 'QTD' : 'Quarter to Date'}`} disableFilterColumn={true} field='TwrQtd' cellStyle={getRowStyle} width={isMobile ? 50 : 110} valueGetter={value => {
          return getPerformanceValue(value, 'TwrQtd')
        }} />
        <PercentageColumn hide={!performanceSummarySettings?.performanceSumaryFiscalYtd?.display} customFilter={PERFORMANCE_MEDIAN_KEYS} headerClass='long-text-break-right mtd' title={`${isMobile ? 'Fiscal' : 'Fiscal Year to Date Dec'}`} disableFilterColumn={true} field='TwrFytdDec' cellStyle={getRowStyle} width={isMobile ? 50 : 110} valueGetter={value => {
          return getPerformanceValue(value, 'TwrFytdDec')
        }} />
        <PercentageColumn hide={!performanceSummarySettings?.performanceSumaryYtd?.display} customFilter={PERFORMANCE_MEDIAN_KEYS} headerClass='three-text-break-right mtd' title={`${isMobile ? 'YTD' : 'Year to Date'}`} disableFilterColumn={true} field='TwrCytd' cellStyle={getRowStyle} width={isMobile ? 50 : 110} valueGetter={value => {
          return getPerformanceValue(value, 'TwrCytd')
        }} />
        <PercentageColumn hide={!performanceSummarySettings?.performanceSumaryCustomPeriod?.display} customFilter={PERFORMANCE_MEDIAN_KEYS} headerClass='three-text-break-right mtd' title={`Annalized Since ${portfolioSettings && formatDate(portfolioSettings.inceptionDate, portfolioSettings.fullDateFormat)}`} disableFilterColumn={true} field='TwrCust' cellStyle={getRowStyle} width={isMobile ? 50 : 110} valueGetter={value => {
          return getPerformanceValue(value, 'TwrCust')
        }} />
        <PercentageColumn hide={!performanceSummarySettings?.performanceSumary1Year?.display} customFilter={PERFORMANCE_MEDIAN_KEYS} headerClass='four-text-break-right one-year' title={`${isMobile ? '1Y' : 'Cumulative trailing 1 year'}`} disableFilterColumn={true} field='TwrTr1Y' cellStyle={getRowStyle} width={isMobile ? 50 : 120} valueGetter={value => {
          return getPerformanceValue(value, 'TwrTr1Y')
        }} />

        {/* New columns by here  */}
        <PercentageColumn hide={!performanceSummarySettings?.performanceSumaryAnnualizedSinceInception?.display} headerClass='text-right six-text-break-right' width={155} cellStyle={getRowStyle} disableFilterColumn={true} customFilter={PERFORMANCE_MEDIAN_KEYS} title='ANNUALIZED SINCE INCEPTION' cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }}
          field='TwrItd' valueGetter={(value) => {
            return getPerformanceValue(value, 'TwrItd')
          }} />
        <PercentageColumn disableFilterColumn={true} cellStyle={getRowStyle} headerClass='text-right six-text-break-right' customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceSummarySettings?.performanceSumaryCumulative?.display} title='CUMULATIVE SINCE INCEPTION' width={200}
          field='TwrCumItd' valueGetter={(value) => {
            return getPerformanceValue(value, 'TwrCumItd')
          }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
        <PercentageColumn disableFilterColumn={true} cellStyle={getRowStyle} customFilter={PERFORMANCE_MEDIAN_KEYS} headerClass='text-right six-text-break-right' hide={!performanceSummarySettings?.performanceSumarySinceFirstFullMonth?.display} title='ANNUALIZED SINCE FIRST FULL MONTH' width={211}
          field='TwrS1FM' valueGetter={(value) => {
            return getPerformanceValue(value, 'TwrS1FM')
          }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
        <PercentageColumn disableFilterColumn={true} cellStyle={getRowStyle} customFilter={PERFORMANCE_MEDIAN_KEYS} headerClass='text-right six-text-break-right' hide={!performanceSummarySettings?.performanceSumarySinceFirstFullQuarter?.display} title='ANNUALIZED SINCE FIRST FULL QUARTER' width={211}
          field='TwrS1FQ' valueGetter={(value) => {
            return getPerformanceValue(value, 'TwrS1FQ')
          }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
        <PercentageColumn cellStyle={getRowStyle} disableFilterColumn={true} customFilter={PERFORMANCE_MEDIAN_KEYS} headerClass='text-right six-text-break-right' hide={!performanceSummarySettings?.performanceSumary2Year?.display} title='ANNUALIZED TRAILING 2 YEARS'
          width={160} field='TwrTr2Y' valueGetter={(value) => {
            return getPerformanceValue(value, 'TwrTr2Y')
          }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
        <PercentageColumn hide={!performanceSummarySettings?.performanceSumary3Year?.display} headerClass='text-right six-text-break-right' width={155} cellStyle={getRowStyle} disableFilterColumn={true} customFilter={PERFORMANCE_MEDIAN_KEYS} title='ANNUALIZED TRAILING 3 YEARS' cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }}
          field='TwrTr3Y' valueGetter={(value) => {
            return getPerformanceValue(value, 'TwrTr3Y')
          }} />
        <PercentageColumn headerClass='text-right six-text-break-right' disableFilterColumn={true} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceSummarySettings?.performanceSumary4Year?.display} title='ANNUALIZED TRAILING 4 YEARS' width={160}
          field='TwrTr4Y' valueGetter={(value) => {
            return getPerformanceValue(value, 'TwrTr4Y')
          }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
        <PercentageColumn headerClass='text-right six-text-break-right' disableFilterColumn={true} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceSummarySettings?.performanceSumary5Year?.display} title='ANNUALIZED TRAILING 5 YEARS' width={160}
          field='TwrTr5Y' cellStyle={getRowStyle} valueGetter={(value) => {
            return getPerformanceValue(value, 'TwrTr5Y')
          }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
        <PercentageColumn headerClass='text-right six-text-break-right' disableFilterColumn={true} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceSummarySettings?.performanceSumary6Year?.display} title='ANNUALIZED TRAILING 6 YEARS' width={160}
          field='TwrTr6Y' cellStyle={getRowStyle} valueGetter={(value) => {
            return getPerformanceValue(value, 'TwrTr6Y')
          }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
        <PercentageColumn headerClass='text-right six-text-break-right' disableFilterColumn={true} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceSummarySettings?.performanceSumary7Year?.display} title='ANNUALIZED TRAILING 7 YEARS' width={160}
          field='TwrTr7Y' cellStyle={getRowStyle} valueGetter={(value) => {
            return getPerformanceValue(value, 'TwrTr7Y')
          }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
        <PercentageColumn headerClass='text-right six-text-break-right' disableFilterColumn={true} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceSummarySettings?.performanceSumary8Year?.display} title='ANNUALIZED TRAILING 8 YEARS' width={160}
          field='TwrTr8Y' cellStyle={getRowStyle} valueGetter={(value) => {
            return getPerformanceValue(value, 'TwrTr8Y')
          }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
        <PercentageColumn headerClass='text-right six-text-break-right' disableFilterColumn={true} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceSummarySettings?.performanceSumary9Year?.display} title='ANNUALIZED TRAILING 9 YEARS' width={160}
          field='TwrTr9Y' cellStyle={getRowStyle} valueGetter={(value) => {
            return getPerformanceValue(value, 'TwrTr9Y')
          }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
        <PercentageColumn headerClass='text-right six-text-break-right' disableFilterColumn={true} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceSummarySettings?.performanceSumary10Year?.display} title='ANNUALIZED TRAILING 10 YEARS' width={160}
          field='TwrTr10Y' cellStyle={getRowStyle} valueGetter={(value) => {
            return getPerformanceValue(value, 'TwrTr10Y')
          }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
        <PercentageColumn headerClass='text-right six-text-break-right' disableFilterColumn={true} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceSummarySettings?.performanceSumary11Year?.display} title='ANNUALIZED TRAILING 11 YEARS' width={160}
          field='TwrTr11Y' cellStyle={getRowStyle} valueGetter={(value) => {
            return getPerformanceValue(value, 'TwrTr11Y')
          }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
        <PercentageColumn headerClass='text-right six-text-break-right' disableFilterColumn={true} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceSummarySettings?.performanceSumary12Year?.display} title='ANNUALIZED TRAILING 12 YEARS' width={160}
          field='TwrTr12Y' cellStyle={getRowStyle} valueGetter={(value) => {
            return getPerformanceValue(value, 'TwrTr12Y')
          }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
        <PercentageColumn headerClass='text-right six-text-break-right' disableFilterColumn={true} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceSummarySettings?.performanceSumary13Year?.display} title='ANNUALIZED TRAILING 13 YEARS' width={160}
          field='TwrTr13Y' cellStyle={getRowStyle} valueGetter={(value) => {
            return getPerformanceValue(value, 'TwrTr13Y')
          }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
        <PercentageColumn headerClass='text-right six-text-break-right' disableFilterColumn={true} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceSummarySettings?.performanceSumary14Year?.display} title='ANNUALIZED TRAILING 14 YEARS' width={160}
          field='TwrTr14Y' cellStyle={getRowStyle} valueGetter={(value) => {
            return getPerformanceValue(value, 'TwrTr14Y')
          }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
        <PercentageColumn headerClass='text-right six-text-break-right' disableFilterColumn={true} customFilter={PERFORMANCE_MEDIAN_KEYS} hide={!performanceSummarySettings?.performanceSumary15Year?.display} title='ANNUALIZED TRAILING 15 YEARS' width={160}
          field='TwrTr15Y' cellStyle={getRowStyle} valueGetter={(value) => {
            return getPerformanceValue(value, 'TwrTr15Y')
          }} cellClassRules={{ darkFont: params => params.data.DisplayPLIType === 'Sleeve' }} />
      </DataGrid>
      <PerformanceGridFootNote additionalClassName='PerformanceSummaryFootNote' hidden={isFootNoteHidden} showTitle={false} />
    </div>
  )
}

export default PerformanceSummaryGrid