import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { DefaultButton, Stack } from '@fluentui/react'
import { DEFAULT_DATE_FORMAT, DEFAULT_FULL_DATE_FORMAT, formatDate, getFormattedMaxAsOfDate, getMaxAsOfDate, getMinAsOfDate, parseFormattedDate } from '@/lib/common/services/date/DateService'
import { useGetBoxRecentItemsQuery, useGetBoxTokenQuery } from '@/shared/api/services/boxServices'
import PageHeader from '@/shared/components/PageHeader'
import { useGetDashboardSettings } from '@/shared/api/services/legacyService'
import useAccountName from '@/features/auth/hooks/useAccountName'
import DigestMedia from '@/features/dashboard/components/DigestMedia'
import EventSideSection from '@/features/dashboard/components/EventSideSection'
import RecentDocuments from '@/features/dashboard/components/RecentDocuments'
import InsightList from '@/features/insight/components/InsightList'
import { IInsightTag } from '@/features/insight/shared/types'
import { Card,  MainLayout,  Section, SplitLayout, TeamCard } from '@/shared/components'
import useAccessRulesQuery from '@/features/auth/hooks/useAccessRulesQuery'
import { selectAccountId, selectIsCAEmployee } from '@/features/auth/redux/authSelectors'
import PortfolioList from './PortfolioList/grid/PortfolioList'
import RecentTrades from './RecentTrades/grid/RecentTrades'
import { useState } from 'react'
import { GridApi } from 'ag-grid-community'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useIsMobile from '@/shared/hooks/useIsMobile'
import PortfolioListMobile from './PortfolioList/PortfolioListMobile/PortfolioListMobile'
import { exportGridData, getPerformanceGridProcessCellCallback } from '@/shared/api/services/portfolioService'
import MonthEndSelector from '@/lib/common/components/date/monthend/MonthEndSelector'
import useQueryParamSubscription from '@/shared/hooks/useQueryParamSubscription'

const Dashboard = () => {
  const { data: boxTokenResponse } = useGetBoxTokenQuery(null)
  const navAccessState = useAccessRulesQuery()
  const skipRecentFetch = !boxTokenResponse?.accessToken || !navAccessState?.data?.documents
  const recentItemsState = useGetBoxRecentItemsQuery(null, { skip: skipRecentFetch })
  const isCAEmployee = useSelector(selectIsCAEmployee)
  const { data: dashboardSettings } = useGetDashboardSettings()
  const isMobile = useIsMobile('xs')
  const isLargeMobile = useIsMobile('sm')
  const [asOfDate, setAsOfDate] = useQueryParamSubscription('asOfDate')
  const asOfDateInput = asOfDate || getFormattedMaxAsOfDate()
  const parsedAsOfDate = parseFormattedDate(asOfDateInput)
  const subtitle = `As of ${formatDate(parsedAsOfDate, 'MMM dd, yyyy')}`
  const { accountName } = useAccountName()
  const history = useHistory()
  const accountId = useSelector(selectAccountId)
  const[tradeApi,setTradeApi]= useState(null as GridApi)
  const[portfolioGridApi,setPortfolioGridApi]= useState(null as GridApi)

  const updateFeaturedInsightTags = (tag: IInsightTag) => {
    const selectedTag = tag && JSON.stringify([tag])
    history.push(`/insights?tag=${selectedTag}`)
  }

  const handleExcelDownloadTradeClick = () => {
    exportGridData({
      gridApi: tradeApi,
      excelTabName:'Recent Trades',
      fileName: `client-recent-trades-export-client-${accountName}-as-of-date-${asOfDate}`,
    })
  }

  const handlePortfolioDownloadClick = () => {
    exportGridData({
      gridApi: portfolioGridApi,
      excelTabName: 'Portfolios',
      fileName: `client-portfolios-export-client-${accountName}-as-of-date-${asOfDate}`,
      processCellCallback: getPerformanceGridProcessCellCallback('dashboard'),
    })
  }

  const leftContent = (
    <div className='Dashboard'>
      {!isCAEmployee ? (
        <>
          { dashboardSettings?.enableClientDashboardPortfolioList ? (
            <Card className='dashboard-card c-onboarding-welcome__left__card' ariaLabel="My Portfolios">
              <Section className='c-dashboard-section portfolio-list-container' title="My Portfolios" subtitle={subtitle} actions={[
                (<MonthEndSelector key='MonthEndSelector'
                  minDate={getMinAsOfDate()}
                  maxDate={getMaxAsOfDate()}
                  value={parsedAsOfDate}
                  onValueChange={(date) => setAsOfDate(formatDate(date))}
                />),
                !isMobile ? (<div  key='downloadBtn' className='download-button-container'>
                  <DefaultButton className='download-button'  onClick={handlePortfolioDownloadClick}>
                    <FontAwesomeIcon icon='download' className="c-sidebar-section__heading-icon" />
                  </DefaultButton>
                </div>) : null
              ].filter(c => !!c)}>
                { isLargeMobile ? (<PortfolioListMobile asOfDate={asOfDateInput} accountId={accountId} />) : (<PortfolioList asOfDate={asOfDateInput} accountId={accountId} sendGridApi={ setPortfolioGridApi } />) }
              </Section>
            </Card>
          ) : null }
          <div className="d-flex">
            { dashboardSettings?.enableClientDashboardRecentDocuments && recentItemsState.data?.length  ? (
              <Card className='dashboard-card c-onboarding-welcome__left__card card-left card' ariaLabel="Welcome">
                <RecentDocuments></RecentDocuments>
              </Card>
            ) : null }
            {
              dashboardSettings?.enableClientDashboardRecentDocuments && recentItemsState.data?.length &&
              dashboardSettings?.enableClientDashboardRecentTrades ? (<div className='separator'></div>) : null
            }
            { dashboardSettings?.enableClientDashboardRecentTrades ? (
              <Card className='dashboard-card  c-onboarding-welcome__left__card card-right card' ariaLabel="Welcome">
                <Section className='c-dashboard-section' title="Recent Trades" actions={!isMobile &&[         
                  <div  key='downloadBtn' className='download-button-container'>
                    <DefaultButton className='download-button'  onClick={handleExcelDownloadTradeClick}>
                      <FontAwesomeIcon icon='download' className="c-sidebar-section__heading-icon" />
                    </DefaultButton>
                  </div>,]}>
                  <RecentTrades fullDateFormat={DEFAULT_FULL_DATE_FORMAT} dateFormat={DEFAULT_DATE_FORMAT} accountId={accountId} sendGridApi={ setTradeApi }/>
                </Section>
              </Card>
            ) : null }
          </div>
        </>
      ) : null}
      { (isCAEmployee || dashboardSettings?.enableClientDashboardInsightList) ? (
        <Card className='dashboard-card  c-onboarding-welcome__left__card card-insight' ariaLabel="Welcome">
          <InsightList isDashboard setTags={updateFeaturedInsightTags}/>
        </Card>
      ) : null }
    </div>
  )

  const rightContent = (
    <Stack tokens={{ childrenGap: 24 }}>
      <div className='c-dashboard-items'>
        { (isCAEmployee || dashboardSettings?.enableClientDashboardFeaturedMedia) ? (
          <Stack.Item className='c-first-child-element'>
            <DigestMedia />
          </Stack.Item>
        ) : null }
        {
          dashboardSettings?.enableClientDashboardFeaturedMedia &&
          dashboardSettings?.enableClientDashboardMyCambridgeAssociatesTeam ? (
            <Stack.Item>
              <div className='separator'></div>
            </Stack.Item>
          ) : null
        }
        { (!isCAEmployee && dashboardSettings?.enableClientDashboardMyCambridgeAssociatesTeam) ? (
          <Stack.Item>
            <TeamCard accountId={accountId} label="My Cambridge Associates Team" />
          </Stack.Item>
        ) : null }
      </div>
      
      { (isCAEmployee || dashboardSettings?.enableClientDashboardEventList) ? (
        <Stack.Item>
          <Card className='dashboard-card' ariaLabel="Welcome">
            <EventSideSection />
          </Card>
        </Stack.Item>
      ) : null }
    </Stack>
  )

  return (
    <MainLayout>
      <PageHeader className="dashboard" label={accountName ? `${accountName}'s Dashboard` : 'Dashboard'} />
      <div className='c-dashboard__message-table'>
      </div>
      
      <Section>
        <SplitLayout
          renderHeader={false}
          leftContent={leftContent}
          rightContent={rightContent}
        />
      </Section>
    </MainLayout>
  )
}

export default Dashboard