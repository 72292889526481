import { Card, GeneralError, Section } from '@/shared/components'
import { exportGridData, useGetPortfolioSettingsByIdQuery, useGetPortfolioTopHoldings } from '@/shared/api/services/portfolioService'
import TopFiveHoldingGrid from './TopFiveHoldingGrid/grid/TopFiveHoldingGrid'
import SkeletonClientPortfolio from '../Skeletons/SkeletonClientPortfolio'
import { formatDate, parseFormattedDate } from '@/lib/common/services/date/DateService'
import { DefaultButton } from '@fluentui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ColDef, GridApi, ProcessCellForExportParams } from 'ag-grid-community'
import { useState } from 'react'
import useIsMobile from '@/shared/hooks/useIsMobile'
import { CURRENCY_COLUMN_CELL_CLASS, DATE_COLUMN_CELL_CLASS, DATE_COLUMN_FORMAT, DATE_TIME_COLUMN_CELL_CLASS, DATE_TIME_COLUMN_FORMAT, PERCENTAGE_COLUMN_CELL_CLASS } from '@/lib/common/components/grid/GridExport'
import { PERFORMANCE_SLEEVE_COLUMN_CELL_CLASS } from '../../Components/Grid/Column/PerformanceSleeveColumn'
import PerformanceGridFootNote from '../../Components/Grid/FootNote/PerformanceGridFootNote'

import './TopFiveHolding.scss'

export interface TopFiveHoldingProps {
  portfolioId: string, 
  asOfDate: string 
}

export default function TopFiveHolding({ 
  portfolioId, 
  asOfDate,
}: TopFiveHoldingProps) {
  const { data, isFetching, isError, refetch } = useGetPortfolioTopHoldings({ portfolioId, asOfDate })
  const { data: portfolioSettings } = useGetPortfolioSettingsByIdQuery(portfolioId)
  const parsedAsOfDate = parseFormattedDate(asOfDate)
  const subtitle = `As of ${formatDate(parsedAsOfDate, portfolioSettings?.headerDateFormat || 'MMM dd, yyyy')}`
  const isMobile= useIsMobile('xs')
  const [ gridApi, setGridApi ] = useState(null as GridApi)
  const isFootNoteHidden = !(data || []).some(r => !!r.StatusAggregate)
  
  if (isError) {
    return (
      <Card className='c-onboarding-welcome__right__card card-right TopFiveHolding c-top-five-card' ariaLabel='Welcome'>
        <Section className='c-portfolio-section' title='Top 5 Total Portfolio Holdings' subtitle={subtitle}>
          <GeneralError title='Failed to load Portfolio Top 5 Total Portfolio Holdings' onClick={refetch} />
        </Section>
      </Card>
    )
  }

  const handleExcelDownloadClick = () => {
    exportGridData({
      gridApi,
      excelTabName: 'Top 5 Total Portfolio Holdings',
      fileName: `top-5-total-portfolio-holdings-export-investment-pool-${portfolioSettings?.investmentPoolName}-${portfolioSettings?.investmentPoolId}-as-of-date-${asOfDate}`,
      processCellCallback:  (cellCallbackParams: ProcessCellForExportParams) => {
        const { value, column, node } = cellCallbackParams
        const { colDef: columnDef } = column as any
        const colDef = columnDef as ColDef
        const { originalCellClass } = colDef as any
        const { cellClass } = colDef
        const data = node && node.data
        const field = colDef && colDef.field
        const className = (originalCellClass && (typeof originalCellClass === 'string')) ? originalCellClass : cellClass
    
        if (value && cellClass) {
          const hasCellClass = (cellClassName: any, expectedCellClass: string) => {
            if (Array.isArray(cellClassName)) {
              return cellClassName.includes(expectedCellClass)
            }
            return `${cellClassName || ''}`.includes(expectedCellClass)
          }
    
          if (hasCellClass(className, DATE_COLUMN_CELL_CLASS)) {
            return formatDate(value, DATE_COLUMN_FORMAT)
          } else if (hasCellClass(className, DATE_TIME_COLUMN_CELL_CLASS)) {
            return formatDate(value, DATE_TIME_COLUMN_FORMAT)
          } else if (hasCellClass(className, CURRENCY_COLUMN_CELL_CLASS)) {
            if (data && field) {
              try {
                const currencyValue = Number(data[field] || value)?.toFixed(4)
                return currencyValue
              } catch (error) {
                console.error('Error while parsing currency column value.', error)
              }
            }
          } else if (hasCellClass(className, PERCENTAGE_COLUMN_CELL_CLASS)) {
            try {
              return value?.toFixed(6)
            } catch (error) {
              console.error('Error while parsing percentage column value.', error)
            }
          }
          else if (hasCellClass(className, PERFORMANCE_SLEEVE_COLUMN_CELL_CLASS)) {
            const { StatusAggregate, footNoteDataFlags  } = data || {}
            let dataFlags = ''
    
            if (StatusAggregate) {
              if (StatusAggregate === 'Preliminary') {
                dataFlags += ' * '
              } else if (StatusAggregate === 'PROXY') {
                dataFlags += ' † '
              }
            }
            
            if (footNoteDataFlags && footNoteDataFlags.length) {
              // [CP-436] - uncomment when UI development complete
              // dataFlags += ' '  + footNoteDataFlags.join(' ')
            }

            return value + dataFlags
          }
        }
        return value
      }
    })
  }

  return (
    <Card className='c-onboarding-welcome__right__card card-right c-top-five-card' ariaLabel='Welcome'>
      {
        isFetching ? <SkeletonClientPortfolio /> :
        <Section className='c-portfolio-section' title='Top 5 Total Portfolio Holdings' subtitle={subtitle} actions={[
          <div  key='downloadRecentTradesBtn' className='download-button-container'>
            <DefaultButton className='download-button'  onClick={handleExcelDownloadClick}>
              <FontAwesomeIcon icon='download' className='c-sidebar-section__heading-icon' />
            </DefaultButton>
          </div>,
        ]}>
          <TopFiveHoldingGrid portfolioSettings={portfolioSettings} rows={data} sendGridApi={setGridApi} />
          <PerformanceGridFootNote additionalClassName='TopFiveHoldingFootNote' hidden={isFootNoteHidden} showTitle={false} />
        </Section>
      }
    </Card>
  )
}
